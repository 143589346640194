import vijit from '../../../assets/team/vijit_verma.png'
import priyank from '../../../assets/team/priyank_aggarwal.jpg'
import mayank from '../../../assets/team/mayank_srivastava.jpeg'
import ayush from '../../../assets/team/ayush_kumar_gupta.jpeg'
import prajakta from '../../../assets/team/prajakta_singhal.jpeg'
import saurabh from '../../../assets/team/saurabh_agarwal.jpg'
import bhaskar from '../../../assets/team/bhaskar_singh.jpeg'
import gopal from '../../../assets/team/gopal_aggarwal.jpg'
import prachi from '../../../assets/team/prachi_singh.jpeg'
import shruti from '../../../assets/team/shruti_mohanty.jpg'
import devansh from '../../../assets/team/devansh_bajaj.jpeg'
import kartik from '../../../assets/team/kartik_kaushik.jpeg'
import vinit from '../../../assets/team/vinit_baishkiyar.jpeg'

export const teamLeads = [
    // Technical Operations
    {
        name: "Mr. Vijit Verma",
        title: 'Technical Operations',
        img: vijit
    },
    {
        name: "Mr. Ayush Kumar Gupta",
        title: 'Technical Operations',
        // title: 'Resource Management & Activities',
        img: ayush
    },
    {
        name: "Mr. Mayank Srivastava",
        title: 'Technical Operations',
        img: mayank
    },
    {
        name: "Mr. Priyank Aggarwal",
        title: 'Technical Operations',
        img: priyank
    },
    {
        name: "Ms. Prajakta Singhal",
        title: 'Social Media & Marketing',
        img: prajakta
    },
    {
        name: "Mr. Saurabh Agrawal",
        title: "Social Media & Marketing",
        img: saurabh,
    },
    {
        name: "Mr. Bhaskar Singh",
        title: "Planning & Strategy",
        img: bhaskar,
    },
    {
        name: "Mr. Gopal Aggarwal",
        title: "Planning & Strategy",
        img: gopal,
    },
    {
        name: "Ms. Prachi Singh",
        title: "Content & Documentation",
        img: prachi,
    },
    {
        name: "Ms. Shruti Mohanty",
        title: "Content & Documentation",
        img: shruti,
    },
    {
        name: "Mr. Devansh Bajaj",
        title: "Outreach",
        img: devansh,
    },
    {
        name: "Mr. Kartik Kaushik",
        title: "Outreach",
        img: kartik,
    },
    {
        name: "Mr. Vinit Baishkiyar",
        title: "Outreach",
        img: vinit,
    },
]

// {
//     name: 'Mr. Abhishek Yadav',
//     title: 'Social Media & Marketing',
//     img: abhishek
// },
// {
//     name: 'Mr. Aryan Garg',
//     title: 'Social Media & Marketing',
//     img: aryan
// },
// //? Head Mentor-Mentee & Outreach
// {
//     name: 'Ms. Sakshi Sharma',
//     title: 'Mentor-Mentee & Outreach',
//     img: sakshi
// },
// {
//     name: 'Mr. Harsh Jadoun',
//     title: 'Mentor-Mentee & Outreach',
//     img: harsh
// },
// // ? Head of Mock Interview & Technical Program
// {
//     name: 'Ms. Vrisha Garg',
//     title: 'Mock Interview & Technical Program',
//     img: vrihsa
// },
// {
//     name: 'Mr. Divyansh Agarwal',
//     title: 'Mock Interview & Technical Program',
//     img: divyansh
// }