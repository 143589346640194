import Anshika from '../../../assets/team/anshika_verma.jpeg'
import Divyam from '../../../assets/team/divyam_kumar.JPG'
import Tashvi from '../../../assets/team/tashvi_chaudhary.jpg'
import UtkarshK from '../../../assets/team/utkarsh_kumar.jpg'
import UtkarshS from '../../../assets/team/utkarsh_sharma.jpg'

export const teamExec = [
  {
    name: "Mr. Utkarsh Sharma",

    img: UtkarshS,
  },
  {
    name: "Ms. Tashvi Chaudhary",

    img: Tashvi,
  },
  {
    name: "Mr. Divyam Kumar",

    img: Divyam,
  },
  {
    name: "Ms. Anshika Verma",

    img: Anshika,
  },
  {
    name: "Mr. Utkarsh Kumar",

    img: UtkarshK,
  }
];
